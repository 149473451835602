import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import VueAxios from 'vue-axios';
import axios from './plugins/axios';
import router from './routes';
import {store} from "./store/index";
import vSelect from 'vue-select'
import {VueReCaptcha} from 'vue-recaptcha-v3'
import VueConfetti from 'vue-confetti'
import underscore from 'vue-underscore'

import {i18n} from './translations'


Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(underscore);
Vue.component('v-select', vSelect)
Vue.use(VueConfetti)

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPCHA_KEY })

Vue.config.productionTip = false;


new Vue({
    el: '#app',
    store: store,
    i18n: i18n,
    router: router,
    render: h => h(App),
});