<template>

<span>
    <div class="card-body text-center text-muted">
        <p v-if="error" class="alert alert-danger text-left" role="alert">{{$t(error)}}</p>
 <stepper v-bind:ascent-color="system_settings.accent_color"></stepper>
        <!--        <div class="title">-->
        <!--            <h4 class="page-title title-font"> {{$t('VERIFY')}} {{$t('BARCODE')}}</h4>-->
        <!--        </div>-->
        <div id="cover-spin" v-if="loading"></div>
        <!--        <p v-if="info.user_email">{{$t('IDENTIFIER_TEXT').replace('${USER_EMAIL}',info.user_email)}}-->
        <!--       <p class = "header-info">{{$t('LANDING_TEXT')}}</p>     .</p>-->
        <!--        <p v-if="info.deal_name"> {{$t('DEAL_IDENTIFIER_TEXT')}} {{info.deal_name}}-->
        <!--        </p>-->
<div class="row">
    <div class="col-md-12 ">
        <p class = "header-info">{{$t('PAYMENT_TEXT')}}</p>
    </div>
</div>

        <div class="row">

            <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                <form @submit.prevent="verify" class="text-left">
                    <div class="form-group row">

                        <div class="col-sm-12">
                            <input type="text" name="bar_code" v-model="info.iban" class="form-control" value=""
                                   required>
                            <span class="text-danger">{{validation_errors.iban}}</span>
                        </div>
                    </div>
<!--                    <div class="form-group text-center pt-4">-->
<!--                        <button-->
<!--                                :disabled="loading"-->
<!--                                v-bind:style="{'background-color':system_settings.accent_color,color:  system_settings.accent_text_color}"-->
<!--                                type="submit" class="btn btn-info px-4 py-3">{{$t('CONFIRM_BUTTON_TEXT')}}-->
<!--                        </button>-->
<!--                    </div>-->
                </form>
            </div>
        </div>
    </div>
    <div class="bottom-actions">
        <div class="form-group text-center pr-4 pt-4 float-right">
         <button @click="verify"
                 :disabled="loading"
                 v-bind:style="{'background-color':system_settings.accent_color,color:  system_settings.accent_text_color}"
                 type="submit" class="btn btn-info next px-4 py-2">{{$t('CONTINUE')}}
                        </button>
            </div>
            <div class="form-group text-center pl-4 pt-4 float-left start-over">
        <a href="/"
           :disabled="loading"
        >{{$t('START_OVER')}}
        </a>
        </div>
    </div>
</span>
</template>
<script>
    import Stepper from './StepperBar.vue';

    export default {
        data() {
            return {
                info: { iban: ''},
                error: false,
                loading: false,
                status: true,
                validation_errors: {iban: ''}
            }
        },
        created() {
            this.loading = true
            this.axios

                .get('/payment-details', {
                    headers: {
                        'Accept': 'application/json'
                    }
                })

                .then(response => {

                    this.info = response.data;
                    if (response.data.statusCode == 0) {
                        // this.$router.history.base = this.$router.history.base+'/fr'
                        this.info = response.data;
                    } else {
                        this.error = response.data.debug;
                    }
                }).catch((error) => {

                console.log('error' + error);
            }).finally(() => this.loading = false);

        },

        methods: {
             verify() {
                this.loading = true;
                this.error = false;



                this.axios
                    .post('/check-iban', {
                        'iban': this.info.iban
                    }, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'

                        }
                    }).then(response => {
                    if (response.data.statusCode == 0) {
                        this.$router.push({name: 'recap'});
                    } else {
                        if (response.data.validation_errors != undefined && Object.keys(response.data.validation_errors).length > 0) {
                            this.validation_errors = response.data.validation_errors;
                        } else {
                            this.error = response.data.debug;
                        }
                    }
                })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            },
            startOver() {
                this.$router.push({name: 'home'});
            }
        },
        computed: {
            system_settings() {
                if (this.$store.state.systemSettings.length === 0) {
                    return {ascent_color: '', ascent_text_color: ''}

                } else {
                    return this.$store.state.systemSettings.siteDefaultContent;
                }
            }

        },
        components: {
            Stepper
            // StepNavigation
        },

    }
</script>