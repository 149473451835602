import Vue from 'vue'
import Vuex from 'vuex'
import VueAxios from 'vue-axios';
import axios from '../plugins/axios';
import cookie from "cookie";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

// import systemSettings from './system-settings'
//
// export const store = new Vuex.Store({
//     debug: false,
//     modules: {
//         systemSettings
//     }
// });


axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use((config) => {
    let token = cookie.parse(document.cookie)['XSRF-TOKEN'];

    if (token) {
        axios.defaults.headers.common['X-XSRF-TOKEN'] = token;
    }
    return config;
});

// store.js
export const store = new Vuex.Store({
    state: {
        systemSettings: [],
        validationCode: ''
    },
    mutations: {
        setSystemSettings(state, systemSettings) {
            state.systemSettings = systemSettings;
        }, setValidationCode(state, validationCode) {
            state.validationCode = validationCode;
        }
    },
    actions: {
        fetchSystemSettings({commit}) {
            return new Promise((resolve, reject) => {
                axios.get('/system-settings', {
                    headers: {
                        'Accept': 'application/json'
                    }
                }).then(response => {
                        // console.log(response.data);
                        commit('setSystemSettings', response.data);
                        // state.systemSettings = response.data;
                        resolve(response);
                    }).catch((error) => {
                        console.log('error' + error);
                        reject(error);
                    });
            });
        },
        fetchValidationCode: ({commit, state}, newValue) => {
            // var str =   newValue.match(/.{1,2,3,4}/g);
            // if(str.length >0){
            //     newValue = str.join('-');
            // }
            commit('setValidationCode', newValue)
            return state.validationCode
        },
    }
});

