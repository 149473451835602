<template>

    <div class="card-body text-center text-muted">

        <p v-if="error" class="alert alert-danger text-left" role="alert">{{$t(error)}}</p>
        <stepper v-bind:ascent-color="system_settings.accent_color"></stepper>
        <div class="title">
            <h3 class="page-title" style="font-size: 4em"> {{$t('CONGRATS')}}!</h3>
        </div>
        <div id="cover-spin" v-if="loading"></div>
        <p class="card-text text-center recap-text" v-html="(((($t('RED_REQ_SUCCESS')
            .replace('${IBAN}',info.iban))
            .replace('${PAYMENT_DATE}',info.payment_date))
            .replace('${AMOUNT_REFUND}',info.ben_amount))
            .replace('${CURRENCY}' , info.price_currency))
            .replace('${PAYMENT_REFERENCE}' , info.bar_code)" v-if="!error">

        </p>
        <!--        <div class="row text-left">-->

        <!--            <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1">-->
        <!--&lt;!&ndash;                <div class="form-group row" v-if="uploaded_image_url">&ndash;&gt;-->
        <!--&lt;!&ndash;                    <div class="col-md-12 col-sm-8 col-6">&ndash;&gt;-->
        <!--&lt;!&ndash;                        <div  class="uploaded_file_view" >&ndash;&gt;-->
        <!--&lt;!&ndash;                            <img :src="uploaded_image_url"/>&ndash;&gt;-->
        <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
        <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
        <!--&lt;!&ndash;                </div>&ndash;&gt;-->
        <!--                <div class="form-group row">-->
        <!--                    <label class="col-md-4 col-sm-4 col-6 col-form-label">{{$t('BARCODE')}}</label>-->
        <!--                    <div class="col-md-8 col-sm-8 col-6">-->
        <!--                        <div type="text" readonly-->
        <!--                             class="form-control-plaintext">{{info.bar_code}}-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->


        <!--                <div class="form-group row">-->
        <!--                    <label class="col-md-4 col-sm-4 col-6 col-form-label">{{$t('PRODUCT')}}</label>-->
        <!--                    <div class="col-md-8 col-sm-8 col-6">-->
        <!--                        <div type="text" readonly-->
        <!--                             class="form-control-plaintext">{{info.selected_product_ean}}-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="form-group row">-->
        <!--                    <label class="col-md-4 col-sm-4 col-6 col-form-label">{{$t('PRICE')}}</label>-->
        <!--                    <div class="col-md-8 col-sm-8 col-6">-->
        <!--                        <div type="text" readonly-->
        <!--                             class="form-control-plaintext">{{info.price_currency}} {{info.price}}-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="form-group row">-->
        <!--                    <label class="col-md-4 col-sm-4 col-6 col-form-label">{{$t('REFUND_AMOUNT_TEXT')}}</label>-->
        <!--                    <div class="col-md-8 col-sm-8 col-6">-->
        <!--                        <div type="text" readonly-->
        <!--                             class="form-control-plaintext">{{info.price_currency}} {{info.ben_amount}}-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="form-group row">-->
        <!--                    <label class="col-md-4 col-sm-4 col-6 col-form-label">{{$t('PAYMENT_DATE')}}</label>-->
        <!--                    <div class="col-md-8 col-sm-8 col-6">-->
        <!--                        <div type="text" readonly-->
        <!--                             class="form-control-plaintext">{{info.payment_date}}-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="form-group row">-->
        <!--                    <label class="col-md-4 col-sm-4 col-6 col-form-label">{{$t('IBAN')}}</label>-->
        <!--                    <div class="col-md-8 col-sm-8 col-6">-->
        <!--                        <div type="text" readonly-->
        <!--                             class="form-control-plaintext">{{info.iban}}-->
        <!--                        </div>-->

        <!--                    </div>-->

        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>

</template>
<script>
    import Stepper from './StepperBar.vue';
    export default {
        data() {
            return {
                info: {},
                loading: false,
                uploaded_image_url: false
            }
        },
        created() {
            this.loading = true;
            this.axios

                .get('/recap', {
                    headers: {
                        'Accept': 'application/json'
                    }
                })

                .then(response => {
                    if (response.data.statusCode == 0) {

                        this.info = response.data;
                        this.$confetti.start();

                        var vm = this;
                        setTimeout(function(){ vm.$confetti.stop(); }, 2000);

                    } else {
                        this.error = response.data.debug;
                    }
                }).catch((error) => {
                console.log('error :' + error);
            }).finally(() => this.loading = false)

        },
        computed: {
            system_settings() {
                if (this.$store.state.systemSettings.length === 0) {
                    return {ascent_color: '', ascent_text_color: ''}

                } else {
                    return this.$store.state.systemSettings.siteDefaultContent;
                }
            }

        },
        components: {
            Stepper
        },
    }
</script>

<style>
    .coloredText{
        font-weight: bold;
        background-color: yellow;
    }
    .recap-text{
        line-height: 1.6
    }
</style>