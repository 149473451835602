import Home from '../components/RedemptionHome.vue';
import Upload from '../components/UploadVoucher.vue';
import Products from '../components/AddProducts.vue';
import Recap from '../components/ProcessRecap.vue';
import PaymentDetails from "../components/PaymentDetails";
import VueRouter from "vue-router";
import {store} from "@/store";


const routes = [
{
    name: 'home',
    path: '/',
    props: {currentstep:1},
    component: Home
} ,
{
    name: 'upload-voucher',
    path: '/upload-voucher',
    props: {currentstep:2},
    component: Upload
}
,
{
    name: 'products',
    path: '/products',
    props: {currentstep:3},
    component: Products
},
{
    name: 'recap',
    path: '/recap',
    props: {currentstep:4},
    component: Recap
},
{
    name: 'payment-details',
    path: '/payment-details',
    props: {currentstep:5},
    component: PaymentDetails
}
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    routes: routes
});

router.beforeEach((to, from, next) => {
    // console.log(store.state.systemSettings);
    if (from.path === "/") {
        store.dispatch('fetchSystemSettings')
          .then(
        // loadLanguageAsync(store.state.systemSettings.locale).then(
              next()
              // )
          ).catch()
        ;
    } else {
        next();
    }

})


/*router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        //NProgress.start()
    }
    next()
});*/

/*router.afterEach((to, from) => {
// Complete the animation of the route progress bar.
//NProgress.done()
});*/

export default router;
